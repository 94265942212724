<template>
  <div class="container">
    <div class="theme">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209111112169.png" />
    </div>
    <div class="lottery-box">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209111836716.png" />
      <div class="scroll-cont">
        <div class="lucky-box">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209142211671.png" />
          <div class="swappe-cont" :style="`transform: translateX(-${scrollLeft}px);`">
            <div class="lucky-gift" v-for="(item,index) in prizeList" :key="index">
              <img v-if="item.state === 1" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209134956815.png" />
              <img v-else src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211213100936900.png" />
            </div>
          </div>
          <div class="over-mask" v-show="!luckyResult">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209140928950.png" />
          </div>
          <div class="cover-area">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209141233682.png" />
          </div>
        </div>
        <div class="last-count">
          <div class="con-txt">剩余<span>{{ luckNums }}</span>次抽奖机会</div>
        </div>
      </div>
      <div class="operate">
        <!-- <van-button type="default" @click="handleClick(2)">增加次数
          <i class="icon-solid-right" />
        </van-button> -->
        <van-button type="default" @click="drawLottery()">立即抽奖</van-button>
      </div>
      <div class="my-record" @click="handleClick(1)">
        我的中奖记录 <i class="icon-solid-right" />
      </div>
    </div>
    <!-- <scroll-list :record-list="recordList" :line-height="30" time-color="#666666" main-color="#000000" light-color="#e00000" bg-color="#f2fcab" shadow-color="#008f37" v-if="recordList.length > 0" /> -->
    <div class="koi-prize">
      <div class="koi-title">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209170410678.png" />
      </div>
      <div class="prize-cont">
        <div class="gift">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209134956815.png" />
        </div>
        <div class="name">蔓薇LAX系列（西梅）<span class="big">价值</span>
          <span>￥</span><span class="big">260</span>
        </div>
      </div>
    </div>
    <div class="rule-cont">
      <div class="rule-title">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209170025795.png" />
      </div>
      <div class="rule-list">
        <div class="rule-item">
          <span class="num">1</span>
          活动期间店主混批采购（西梅/蓝莓/蔓越莓/奶昔4种口味）满足以下规则，可参与幸运锦鲤抽奖活动;
        </div>
        <div class="rule-item">
          <span class="num">2</span>
          抽奖规则：
          <p>(1)累积混批满50件，可得1次抽奖机会；</p>
          <p>(2)累积混批满100件，可得2次抽奖机会；</p>
          <p>(3)2021.12.10上午10点至12.14下午2点获得50送10，100送21福利的订单不参与抽奖活动；</p>
        </div>
        <div class="rule-item">
          <span class="num">3</span>
          中奖奖品将在抽奖活动结束后，由系统统一存至店主蔓薇库存中；
        </div>
        <div class="rule-item">
          <span class="num">4</span>
          因抽奖过程可能遇到网络故障相关问题，出现抽奖页面和中奖结果不一致时，最终中奖结果以“中奖记录”为准；
        </div>
        <div class="rule-item">
          <span class="num">5</span>
          四川蜜蛋网络科技有限公司在法律规定的范围内对本活动有解释权；
        </div>
      </div>
    </div>
    <!-- 中奖弹窗 -->
    <lucky-pup v-if="showPopupLayer" @bindtap="handleClick" :lucky-data="luckyResult" />
  </div>
</template>

<script>
import LuckyPup from './components/LuckyPup';
import ScrollList from './components/ScrollList';
import { randomNum } from '../../utils/public'
import { Subtr } from "../../utils/handle"
import { deliveryMessage, replaceAppToken } from "../../utils/appUtil";
let interval = null;
export default {
  components: { ScrollList, LuckyPup },
  name: 'SlotMachine',
  data() {
    return {
      luckNums: 0,
      prizeList: [],
      scrollLeft: 0,
      luckyResult: null,
      recordList: [],// 所有人的中奖记录
      showPopupLayer: false,
      isInApp: false,
      isLottery: false //是否正在抽奖
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) {
      this.isInApp = true;
      return replaceAppToken(token, () => {
        this.initFun();
      })
    }
    this.initFun();
  },
  methods: {
    initFun() {
      this.initPrizeList();
      this.getRecordList();
      this.getMWActTime();
    },
    //初始化奖品列表
    initPrizeList() {
      let list = [{ state: 0 }, { state: 1 }];
      for (let i = 0; i < 10; i++) {
        list.push({ state: 0 })
        list.push({ state: randomNum(0, 1) });
        list.push({ state: 1 })
      }
      list.push({ state: 0 });
      list.push({ state: 1 });
      console.log(list.length);
      this.prizeList = list;
    },
    getRecordList() {
      this.get('/OrderGoods/Activity/GetGeneralprizeList', { isAll: 1 }, 2).then(res => {
        if (res.code === 1) {
          this.recordList = res.response || [];
        }
      })
    },
    //获取双12蔓薇活动时间
    getMWActTime() {
      this.get("/OrderGoods/Activity/GetHoursAndNums", { temp: +new Date() }, 2).then(json => {
        if (json.code === 1) {
          let { luckNums } = json.response;
          this.luckNums = luckNums;
        }
      })
    },
    //抽奖
    drawLottery() {
      if (this.isLottery) return;
      if (this.luckNums <= 0) {
        this.luckyResult = { type: 0 }
        this.showPopupLayer = true;
        return;
      }
      this.isLottery = true;
      this.luckyResult = null;
      let end = false, scrollLeft = 0, speed = 50, duration = 0;
      let len = this.prizeList.length - 2;
      let maxLen = (len - 2) * 120;
      let luckyResult = null; //中奖结果
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        if (end) {
          if (speed <= 30) {
            if (speed === 30) {
              let num = Math.floor(scrollLeft / 120);
              duration = 360 - (scrollLeft - num * 120);
              let targetNum = num + 5;
              console.log(targetNum, len)
              if (targetNum > len) targetNum = targetNum - len + 2;
              console.log(targetNum, len)
              if (luckyResult.type === 2) {
                this.prizeList[targetNum - 1].state = 1;
              } else {
                this.prizeList[targetNum - 1].state = 0;
                this.prizeList[targetNum].state = 1;
              }
            }
            if (duration > 0) {
              speed = duration / 12;
              speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
              duration -= speed;
            } else {
              speed = 0;
            }
          } else {
            speed -= 5;
          }
          if (speed <= 0) {
            this.isLottery = false;
            clearInterval(interval);
            this.luckyResult = luckyResult;
            this.showPopupLayer = true;
          }
        }
        if (scrollLeft > maxLen) {
          console.log('阻断滚动', scrollLeft, maxLen)
          scrollLeft = scrollLeft - maxLen;
        }
        scrollLeft = Subtr(scrollLeft, -speed);
        this.scrollLeft = scrollLeft;
      }, 100);
      this.get("/OrderGoods/Activity/GetTakeGeneralprize", {}, 2).then(res => {
        if (res.code === 1) {
          this.luckNums = this.luckNums - 1;
          let { prize, showType } = res.response;
          prize.type = showType;
          luckyResult = prize;
          setTimeout(() => { end = true; }, 2000);
        } else {
          end = true;
          clearInterval(interval);
          this.scrollLeft = 0;
          this.isLottery = false;
          this.$toast(res.msg);
        }
      })
    },
    //弹窗点击事件
    handleClick(e) {
      //0关闭弹窗 1查看记录 2采购订货
      this.showPopupLayer = false;
      if (e === 1) this.$router.push(`/lottery/slotRecord?`);
      else if (e === 2) {
        if (this.isInApp) {
          deliveryMessage({ 'action': 'jumpShopkeeperOrder', 'good': '4' })
        } else {
          window.location.href = this.baseHost + '/7999/Arongleweb/html/groupon/groupon_list.html?brandId=4';
        }
      } else {
        this.scrollLeft = 0;
        this.luckyResult = null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209111249209.png')
    no-repeat;
  background-size: 100% auto;
  .theme {
    padding-top: 10px;
    text-align: center;
    img {
      width: 90%;
    }
  }
  .lottery-box {
    position: relative;
    padding: 50px 0 10px;
    > img {
      max-width: 100%;
    }
    .scroll-cont {
      position: absolute;
      top: 10px;
      left: 30px;
      right: 30px;
      .lucky-box {
        padding: 20px 20px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        img {
          width: 100%;
        }
        .swappe-cont {
          position: absolute;
          top: -3px;
          left: calc(50% - 160px);
          width: 240px;
          bottom: 0;
          z-index: 10;
          display: flex;
          animation: 0.1s linear;
          align-items: center;
          .lucky-gift {
            width: 80px;
            height: 80px;
            padding: 5px;
            flex-shrink: 0;
            box-sizing: border-box;
            margin-right: 40px;
            border-radius: 10px;
            box-shadow: 0 5px 0 #6e8201;
            background: linear-gradient(to bottom, #d5fb04, #b5d511);
            background: -webkit-linear-gradient(to bottom, #d5fb04, #b5d511);
            background: -o-linear-gradient(to bottom, #d5fb04, #b5d511);
            background: -moz-linear-gradient(to bottom, #d5fb04, #b5d511);
            img {
              border-radius: 10px;
              background-color: #fff;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .over-mask,
        .cover-area {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          img {
            width: 100%;
          }
        }
        .over-mask {
          top: 20px;
          left: 20px;
          right: 20px;
          bottom: 20px;
          z-index: 20;
        }
        .cover-area {
          left: -10px;
          right: -10px;
          z-index: 30;
        }
      }
      .last-count {
        padding-top: 5%;
        text-align: center;
        position: relative;
        .con-txt {
          color: #fff;
          padding: 2px 10px;
          display: inline-block;
          position: relative;
          z-index: 10;
          border-radius: 20px;
          letter-spacing: 1px;
          background-color: #008f37;
          span {
            color: #fbe671;
          }
        }
        &:before {
          content: '';
          position: absolute;
          left: 20%;
          bottom: 10px;
          width: 60%;
          height: 1px;
          z-index: 1;
          background-color: #008f37;
        }
      }
    }
    .operate {
      position: absolute;
      left: 14%;
      right: 14%;
      bottom: 19.5%;
      text-align: center;
      .van-button {
        border: none;
        height: 32px;
        width: 128px;
        background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209151358329.png')
          no-repeat;
        background-size: 100% 100%;
        .van-button__text {
          color: #fff;
          .icon-solid-right {
            display: inline-block;
            vertical-align: middle;
          }
        }
        &:active:before {
          background: none;
        }
      }
    }
    .my-record {
      position: absolute;
      left: 20%;
      right: 20%;
      bottom: 10%;
      color: #008f37;
      font-size: 14px;
      text-align: center;
      .icon-solid-right {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .koi-prize {
    padding: 0 20px 10px;
    text-align: center;
    .koi-title {
      text-align: center;
      padding-bottom: 10px;
      img {
        width: 185px;
      }
    }
    .prize-cont {
      padding: 6px;
      border-radius: 6px;
      box-sizing: border-box;
      background-color: #008f37;
      .gift {
        border-radius: 6px;
        box-sizing: border-box;
        border: 1px solid #c1e801;
        background-color: #f9ffce;
        img {
          max-width: 60%;
          max-height: 90px;
        }
      }
      .name {
        padding: 5px 0;
        color: #000000;
        font-size: 18px;
        span {
          font-size: 13px;
        }
        .big {
          font-size: 22px;
        }
      }
    }
  }
  .rule-cont {
    padding: 50px 22px;
    position: relative;
    background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209170729590.png')
      no-repeat left bottom;
    background-size: 100% auto;
    .rule-title {
      position: absolute;
      top: 33px;
      z-index: 10;
      left: calc(50% - 79px);
      img {
        width: 158px;
      }
    }
    .rule-list {
      padding: 40px 20px;
      border: 2px solid #008f37;
      border-radius: 10px;
      .rule-item {
        color: #000;
        line-height: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
        .num {
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 15px;
          color: #fff;
          border-radius: 50%;
          line-height: 15px;
          text-align: center;
          background-color: #008f37;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>