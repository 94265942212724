<template>
  <div class="record-list" :style="styleVar">
    <div class="record-item" v-for="(item,index) in datalist" :key="index" :style="item.scrollCss" :class="{'animated':item.scrollCss}">
      <img :src="item.prizeImg" />
      <div class="gift-text ellipsis1">{{item.agentName}}抽中<span>{{ item.prizeName }}</span></div>
      <div class="lucky-time">{{ item.dateTimes }}</div>
    </div>
  </div>
</template>

<script>
let scrollHNum = 0;
let headLinesTimer = null;
export default {
  name: 'ScrollList',
  props: {
    recordList: {
      type: Array,
      value() {
        return []
      }
    },
    lineHeight: Number,
    bgColor: String,
    shadowColor: String,
    timeColor: String,
    mainColor: String,
    hightColor: String //高亮颜色
  },
  data() {
    return {
      datalist: [],
      styleVar: {
        '--lineHeight': this.lineHeight + 'px',
        '--bgColor': this.bgColor,
        '--shadowColor': this.shadowColor,
        '--timeColor': this.timeColor,
        '--mainColor': this.mainColor,
        '--hightColor': this.hightColor
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let list = [];
      this.recordList.forEach((it, i) => {
        it.scrollCss = i === 0 ? this.getScrollCss(1) : ''
        list.push(it);
      });
      this.datalist = JSON.parse(JSON.stringify(list));
      if (list.length > 1) this.letScroll();
    },
    letScroll() {
      headLinesTimer = setInterval(() => {
        let num = scrollHNum + 1;
        let list = this.datalist;
        list.forEach((it, i) => {
          if (i + 1 === num) {
            it.scrollCss = this.getScrollCss(2);
          } else if (i === num) {
            it.scrollCss = this.getScrollCss(1);
          } else {
            it.scrollCss = "";
          }
        })
        if (num === list.length) {
          num = 0;
          list[0].scrollCss = this.getScrollCss(1);
        }
        scrollHNum = num;
      }, 2000);
    },
    getScrollCss(num) {
      return "transform: translate(0,-" + num * this.lineHeight + "px)";
    },
  }
}
</script>

<style lang="scss" scoped>
.record-list {
  position: relative;
  height: var(--lineHeight);
  margin: 0 18px;
  overflow: hidden;
  border-radius: var(--lineHeight);
  background-color: var(--bgColor);
  box-shadow: 0 -2px 0 var(--shadowColor);
  .record-item {
    position: absolute;
    top: 100%;
    left: 10px;
    right: 10px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    height: var(--lineHeight);
    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .gift-text {
      color: var(--mainColor);
      width: calc(100% - 106px);
      display: inline-block;
      span {
        color: #ff4444;
      }
    }
    .lucky-time {
      color: var(--timeColor);
      width: 70px;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
    }
    &.animated {
      transition: all 0.2s linear;
    }
  }
}
</style>