<template>
  <div class="lucky-popup">
    <div class="content" :class="{'lucky' : luckyData.type > 0}">
      <div class="theme">
        <img :src="titleList[luckyData.type]" />
      </div>
      <van-icon name="close" size="28" @click="handleClick(0)" />
      <div class="not-count" v-if="luckyData.type === 0">
        <div class="gift-name">您暂时还没有抽奖机会！</div>
        <!-- <p>立即采购可免费获得抽奖机会噢！</p> -->
      </div>
      <template v-else-if="luckyData.type === 2">
        <div class="gift-name">{{ luckyData.prizeName }}</div>
        <img :src="luckyData.prizeImg" />
      </template>
      <div class="not-lucky" v-else>
        <div class="gift-name">很遗憾，您未中奖！</div>
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211210103825505.png" />
      </div>
      <div class="record-btn" v-if="luckyData.type === 2">
        <van-button type="default" @click="handleClick(1)">查看记录 <i class="icon-solid-right" /></van-button>
      </div>
      <div class="operate-area" v-else-if="luckyData.type === 0">
        <van-button type="default" class="purchase" color="linear-gradient(to bottom, #07ae45, #35bd58)" @click="handleClick(0)">关闭</van-button>
      </div>
    </div>
    <van-overlay :show="true" @click="handleClick(0)" />
  </div>
</template>

<script>
export default {
  name: 'LuckyPup',
  props: {
    luckyData: { //中奖数据 0是没有抽奖机会  1是未中奖的  2是中奖的
      type: Object,
      value() {
        return {}
      }
    }
  },
  data() {
    return {
      titleList: [
        'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211210111321709.png',
        'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211210111258137.png',
        'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209172246311.png'

      ]
    }
  },
  methods: {
    handleClick(type) {
      this.$emit('bindtap', type);
    }
  }
}
</script>
<style lang="scss" scoped>
.lucky-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209174005775.png')  no-repeat center;
  background-size: 100% auto;
  .content {
    position: relative;
    z-index: 100;
    width: 80%;
    padding: 30px 0;
    text-align: center;
    border-radius: 12px;
    background: linear-gradient(to bottom, #ebffb6, #b5e441);
    background: -webkit-linear-gradient(to bottom, #ebffb6, #b5e441);
    background: -o-linear-gradient(to bottom, #ebffb6, #b5e441);
    background: -moz-linear-gradient(to bottom, #ebffb6, #b5e441);
    &.lucky {
      background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209172729102.png')
        no-repeat #fff;
      background-size: 100% 100%;
    }
    .theme {
      position: absolute;
      top: -38px;
      left: -6%;
      right: -6%;
      img {
        width: 100%;
      }
    }
    .van-icon-close {
      position: absolute;
      top: -60px;
      right: -10px;
      color: #fff;
    }

    .gift-name {
      color: #000;
      font-size: 16px;
    }
    > img {
      max-width: 50%;
    }
    .not-lucky {
      img {
        width: 50%;
        padding: 30px 0;
      }
    }
    .not-count {
      padding: 20px 0;
      margin: 20px 10px;
      border-radius: 8px 8px 0 0;
      background-color: #fff;
      .gift-name {
        color: #008f37;
      }
      p {
        color: #666666;
        padding-top: 10px;
      }
    }
    .operate-area {
      padding: 0 10%;
      text-align: center;
      .van-button {
        border: none;
        height: 25px;
        width: 120px;
        border-radius: 25px;
        box-shadow: 0 3px 0 #008f37;
        .van-button__text {
          font-size: 12px;
        }
      }
      .see-btn {
        background-color: #fff;
      }
    }
    .record-btn {
      .van-button {
        border: none;
        height: 45px;
        width: 166px;
        background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209173618586.png')
          no-repeat;
        background-size: 100% 100%;
        .van-button__text {
          color: #fff;
          font-size: 18px;
          .icon-solid-right {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.van-overlay{
  z-index: 10 !important;
}
</style>